<template>
    <div class="ClientsDetails">
        <div class="ClientsDetails--Header">
          <h1>{{$t('community_config_title_moderators')}}</h1>
          <CommunityButton color="var(--accent-color)" type="EL" v-on:click.native="openForm()">{{$t('moderators_admin_adduser')}}</CommunityButton>
        </div>
        <Separator/> 
        <div class="ClientsDetails--Content">
          <div class="ClientsDetails--Users">
            <BoxShadow v-for="m in communityModerators" class="ClientsDetails--User hoverItem" :class="{'selected':user.id == m.id}" v-on:click.native="openForm(m.id)">
              <div>
                <ModeratorAdminSVG :name="m.role+'_BIG'"/>
              </div>
              <div class="ClientsDetails--UserName">
                <p>{{m.name}}</p>
                <p>{{m.surname}}</p>
              </div>
              <div class="ClientsDetails--Actions">
                <ModeratorAdminSVG :name="'EDIT'"/>
                <ModeratorAdminSVG :name="'DELETE'" v-on:click="deleteUser(m.id)"/>
              </div>
            </BoxShadow>
          </div>
          <BoxShadow class="ClientsDetails--Form" v-show="showForm">
            <h2 v-show="!user.id">{{$t('modators_admin_newusertitle')}}</h2>
            <h2 v-show="user.id">{{$t('modators_admin_editusertitle')}}</h2>
            <Separator/> 
            <div class="ClientsDetails--UserInfo">
              <CommunityTextInput ref="name" id="name" :placeholder="$t('name')" v-model="user.name" :required="true"/>
              <CommunityTextInput ref="surname" id="surname" :placeholder="$t('surname')" v-model="user.surname" :required="true"/>
              <CommunityTextInput :disabled="!!user.id" ref="email" id="email" :placeholder="$t('profile_personalization_email')" v-model="user.email" :required="true" :email="true"/>
              <CommunityTextInput ref="password" v-if="!user.id" id="password" :placeholder="$t('password')" v-model="user.password" :type="'password'" :required="true" :minLength="6"/>
            </div>
            <div class="ClientsDetails--UserTypes">
              <BoxShadow class="hoverItem" :class="{'selected':user.role == 'ADMINISTRATOR'}" v-on:click="user.role = 'ADMINISTRATOR'">
                <ModeratorAdminSVG :name="'ADMINISTRATOR'"/><span>{{$t('moderators_admin_administrator')}}</span></BoxShadow>
              <BoxShadow class="hoverItem" :class="{'selected':user.role == 'MODERATOR'}" v-on:click="user.role = 'MODERATOR'">
                <ModeratorAdminSVG :name="'MODERATOR'"/><span>{{$t('moderators_admin_moderator')}}</span></BoxShadow>
              <BoxShadow class="hoverItem" :class="{'selected':user.role == 'OBSERVER'}" v-on:click="user.role = 'OBSERVER'">
                <ModeratorAdminSVG :name="'OBSERVER'"/><span>{{$t('moderators_admin_observer')}}</span></BoxShadow>
            </div>
            <div class="ClientsDetails--Visibility">
              <h3>{{$t('modators_admin_selectvisibility')}}</h3>
              <div class="ClientsDetails--VisibilityTypes">
                <BoxShadow class="ClientsDetails--VisibilityType hoverItem" :class="{'selected':user.allActivities}" v-on:click="user.allActivities = true">
                  <p>{{$t('modators_admin_visibAllActivities')}}</p>
                  <p>{{$t('modators_admin_visibAllActivities_text')}}</p>
                </BoxShadow>
                <BoxShadow class="ClientsDetails--VisibilityType hoverItem" :class="{'selected':!user.allActivities}" v-on:click="user.allActivities = false">
                  <p>{{$t('modators_admin_visibSomeActivities')}}</p>
                  <p>{{$t('modators_admin_visibSomeActivities_text')}}</p>
                </BoxShadow>
              </div>
            </div>
            <div class="ClientsDetails--ActivityList" :key="activitiesRefresh">
              <ActivitySelectionBox :disabled="user.allActivities" :activity="activity" v-for="activity in communityActivities" :active="user.activitiesAllowed.includes(activity.identifier)" v-on:checked="checkedActivity(activity.identifier)" v-on:unchecked="uncheckedActivity(activity.identifier)"/>
            </div>
            <Separator/> 
            <div class="ClientsDetails--Error" v-show="errorMessage">
              {{$t('modators_admin_error')}}
            </div>
            <div class="ClientsDetails--FormActions">
              <CommunityButton color="grey" type="EL" v-on:click="closeForm()">{{$t('action_cancel')}}</CommunityButton>
              <CommunityButton color="var(--accent-color)" type="EL" v-on:click="saveUser()">{{$t('action_save')}}</CommunityButton>
            </div>
          </BoxShadow>
        </div>
    </div>
 </template>
  
  <script>
  import Swal from 'sweetalert2'
  import { mapGetters } from 'vuex'
  import store from '@/store'
  import Separator from '@/components/Separator.vue';
  import BoxShadow from '@/components/BoxShadow.vue';
  import ModeratorAdminSVG from '@/components/ModeratorAdmin/ModeratorAdminSVG.vue';
  import CommunityButton from '@/components/CommunityButton.vue';
  import CommunityTextInput from '@/components/CommunityTextInput.vue';
  import ActivitySelectionBox from '@/components/ActivitySelectionBox.vue';
  import { COMMUNITY_FETCH_ACTIVITIES } from '@/store/community/activities.module';
  import {
    COMMUNITY_FETCH_MODERATORS,
    COMMUNITY_FETCH_MODERATOR,
    COMMUNITY_ADD_MODERATOR,
    COMMUNITY_UPDATE_MODERATOR,
    COMMUNITY_DELETE_MODERATOR
  }  from '@/store/community/moderators.module';
  export default {
    name: 'ClientsDetails',
    components:{
      Separator,
      BoxShadow,
      ModeratorAdminSVG,
      CommunityButton,
      CommunityTextInput,
      ActivitySelectionBox
    },
    computed:{
      ...mapGetters([
        'communityModerators',
        'communityActivities',
        'isCommunityMainAdmin'
      ])
    },
    data(){
      return{
        showForm: false,
        activitiesRefresh: 0,
        errorMessage: false,
        user:{
          activitiesAllowed:[]
        }
      }
    },
    methods:{
      async openForm(id){
        if(id) await this.fetchUser(id)
        else this.setUserData({})
        this.showForm = true
      },
      closeForm(){
        this.showForm = false
        this.setUserData({})
      },
      deleteUser(id){
        let vm = this
      Swal.fire({
        title: this.$t('modators_admin_delete_confirmtitle'),
        text: this.$t('modators_admin_delete_confirmtext'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('action_delete'),
        showCancelButton: true,
        customClass:{
          popup:'swal-customWarning'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(COMMUNITY_DELETE_MODERATOR, {moderatorId:vm.user.id}).then((data)=>{
              vm.editingUser = false;
              if (data.data?.error) {
                Swal.fire({
                    title: data.data.payload == "Can't delete main admin" ? 'No se puede eliminar este usuario' : 'Ha ocurrido un error al intentar eliminar el usuario',
                    text: data.data.payload == "Can't delete main admin" ? 'No se puede eliminar el usuario porque es un main admin' : 'Póngase en contacto con nosotros',
                    icon: 'error'
                })
                return
              }
              Swal.fire({
                title: vm.$t('modators_admin_success_title'),
                text: vm.$t('modators_admin_delete_text'),
                icon: 'success',
                confirmButtonText: this.$t('action_accept'),
                customClass:{
                  popup:'swal-customSuccess'
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  store.dispatch(COMMUNITY_FETCH_MODERATORS);
                }
              })
          });
        }
      })
      },
      saveUser(){
        let vm = this
      this.errors = []
      this.errorMessage = false;
        if (!this.user.id){
          let error ="";
          error = this.$refs.name.validate();
          if (error) this.errors.push(error);
          error = this.$refs.surname.validate();
          if (error) this.errors.push(error);
          error = this.$refs.email.validate();
          if (error) this.errors.push(error);
          error = this.$refs.password.validate();
          if (error) this.errors.push(error);
          if(this.errors.length == 0){
            store.dispatch(COMMUNITY_ADD_MODERATOR, {data:this.user}).then((response)=>{
                if (response.status == 400){
                  if (response.data.payload.includes("email")){
                    Swal.fire({
                      title:this.$t('modators_admin_error_title'),
                      text:this.$t('modators_admin_error_email'),
                      icon:"error",
                      customClass:{popup:"swal-customError"},
                    })
                  } else{
                    Swal.fire({
                      title:this.$t('modators_admin_error_title'),
                      text:this.$t('modators_admin_error_saving'),
                      icon:"error",
                      customClass:{popup:"swal-customError"},
                    })
                  }
                } else if(response.newClient) {
                  Swal.fire({
                    title: vm.$t('modators_admin_success_title'),
                    text: vm.$t('modators_admin_success_text'),
                    icon: 'success',
                    confirmButtonText: vm.$t('action_accept'),
                    customClass:{
                      popup:'swal-customSuccess'
                    }
                  }).then((result) => {
                    if (result.isConfirmed) {
                      store.dispatch(COMMUNITY_FETCH_MODERATORS);
                      vm.user= {
                        id: 0,
                        name:"",
                        surname:"",
                        email:"",
                        password:"",
                        role:"MODERATOR",
                        allActivities: true,
                        activitiesAllowed: []
                      }
                    }
                  })
                }
                else{
                  Swal.fire({
                    title: vm.$t('modators_admin_success_title'),
                    text: vm.$t('modators_admin_success_text_2',{email:response.email}),
                    icon: 'success',
                    confirmButtonText: vm.$t('action_accept'),
                    customClass:{
                      popup:'swal-customSuccess'
                    }
                  }).then((result) => {
                    if (result.isConfirmed) {
                      store.dispatch(COMMUNITY_FETCH_MODERATORS);
                      vm.user= {
                        id: 0,
                        name:"",
                        surname:"",
                        email:"",
                        password:"",
                        role:"MODERATOR",
                        allActivities: true,
                        activitiesAllowed: []
                      }
                    }
                  })
                }
            });
          } else {
            this.errorMessage = true;
          }
        }else{
          let error ="";
          error = this.$refs.name.validate();
          if (error) this.errors.push(error);
          error = this.$refs.surname.validate();
          if (error) this.errors.push(error);
          if(this.errors.length == 0){
            store.dispatch(COMMUNITY_UPDATE_MODERATOR, {id:this.user.id,data:this.user}).then((response)=>{
              if (response.status == 400){
                if (response.data.payload.includes("email")){
                  Swal.fire({
                    title:this.$t('modators_admin_error_title'),
                    text:this.$t('modators_admin_error_email'),
                    icon:"error",
                    customClass:{popup:"swal-customError"},
                  })
                } else{
                  Swal.fire({
                    title:this.$t('modators_admin_error_title'),
                    text:this.$t('modators_admin_error_saving'),
                    icon:"error",
                    customClass:{popup:"swal-customError"},
                  })
                }
              } else {
                Swal.fire({
                  title: this.$t('modators_admin_success_title'),
                  text: this.$t('modators_admin_success_text'),
                  icon: 'success',
                  confirmButtonText: this.$t('action_accept'),
                  customClass:{
                    popup:'swal-customSuccess'
                  }
                }).then((result) => {
                  if (result.isConfirmed) {
                    store.dispatch(COMMUNITY_FETCH_MODERATORS);
                  }
                })
              }
            });
          } else {
            this.errorMessage = true;
          }
        }
      },
      uncheckedActivity(activityId){
        if (this.user.activitiesAllowed.indexOf(activityId) !== -1){
            this.user.activitiesAllowed.splice(this.user.activitiesAllowed.indexOf(activityId),1);
        }
      },
      setUserData(data){
        this.user.id = data.id;
        this.user.name = data.name;
        this.user.surname = data.surname;
        this.user.email = data.email;
        this.user.role = data.role;
        this.user.allActivities = data.allActivities;
        if (data.activitiesAllowed && data.activitiesAllowed.length > 0)  this.user.activitiesAllowed = data.activitiesAllowed;
        else this.user.activitiesAllowed = [];
      },
      async fetchUser(id){
        await store.dispatch(COMMUNITY_FETCH_MODERATOR, {moderatorId: id}).then((data)=>{
          this.setUserData(data)
          this.activitiesRefresh++;
        });
      },
      fetchActivities: async function() {
        let priority = "myActivities"
        return await store.dispatch(COMMUNITY_FETCH_ACTIVITIES, {priority});
      },
      fetchModerators: async function() {
        return await store.dispatch(COMMUNITY_FETCH_MODERATORS);
      }
    },
    mounted(){
      if(!this.isCommunityMainAdmin) return
      this.fetchModerators()
      this.fetchActivities()
    }
  }
  </script>
  <style lang="scss">
    .ClientsDetails{
      .CommunityButton {
        font-size: 14px;
      }
      &--User{
        gap: 15px;
        align-items: center;
      }
      &--UserName{
        width: 100%;
        p{
          margin-bottom: 0;
        }
        p:nth-child(1){
          font-weight: bold;
          font-size: 18px;
        }
      }
      &--Actions{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
      }
      &--UserTypes{
          display: flex;
          gap: 15px;
          font-size: 14px;
          margin: 15px 0;
          padding: 0 0.5rem;
          svg{
            width: 20px;
          }
          .BoxShadow{
            align-items: center;
            gap: 5px;
            width: 100%;
          }
          @media only screen and (max-width: 600px) {
            flex-direction: column;
  }
        }
    }
  </style>
  <style scoped lang="scss">
    .ClientsDetails{
        .hoverItem{
          &:hover, &.selected{
            border: 1px solid #FF1564;
            background: #FFF5F5;
            cursor: pointer;
          }
        }
        h1{
            font-weight: bold;
            font-size: 22px;
            letter-spacing: 0.01em;
            color: #3E3E3E;
            margin-bottom: 5px;
        }
        h2{
          font-size: 20px;
        }
        h3{
          font-size: 18px;
        }
        &--Header{
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          @media only screen and (max-width: 600px) {
          flex-direction: column;
          }
        }
        &--Content{
          display: flex;
          width: 100%;
          gap: 15px;
        }
        &--Users{
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 15px;
        }
        &--Form{
          width: 100%;
          flex-direction: column;
        }
        &--UserInfo{
          display: grid;
          grid-template-columns: auto auto;
          .TextInput{
            width: 100%;
          }
          @media only screen and (max-width: 600px) {
          grid-template-columns: auto;

  }
        }
        &--Visibility {
          display: flex;
          flex-direction: column;
          padding: 0 0.5rem;
          gap: 10px;
        }
        &--VisibilityTypes{
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        &--VisibilityType{
          flex-direction: column;
          p:first-of-type{
            width: 100%;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 5px;
          }
        }
        &--ActivityList{
          padding: 20px 0.5rem 0px 0.5rem;
        }
        &--Error{
          background-color: #d9534f;
          color: white;
          padding: 3px;
          font-size: 0.9em;
          margin-bottom: 10px;
        }
        &--FormActions{
          display: flex;
          justify-content: flex-end;
          width: 100%;
          gap: 10px;
        }
        @media (max-width: 1080px){
          &--Content{
            flex-direction: column;
          }
        }
    }
  </style>  