<template>
    <div class="GamificationDetails">
        <h1>{{$t('community_config_title_gamification')}}</h1>
        <Separator/>
        <div class="GamificationDetails--Switcher">
          <i v-on:click="openModalGamification()" class="fas fa-info"></i>
          <label>{{$t('community_config_active_gamification')}}</label>
          <SwitchInput class="SwitchInput--Small" v-model="data.showGamification"/>
        </div>
        <div class="GamificationDetails--Disabled" v-show="!data.showGamification">
          <div>
            <img src="@/assets/img/gamification-image.svg" alt="">
          </div>
          <div class="GamificationDetails--ActiveGamification">
            <h2>{{$t('community_config_gamification_question')}}</h2>
            <p>{{$t('community_config_gamification_explanation')}}</p>
            <CommunityButton color="var(--accent-color)" type="EL" v-on:click="(data.showGamification = !data.showGamification)">Activar gamificación</CommunityButton>
          </div>
        </div>
        <div class="GamificationDetails--Active" v-show="data.showGamification">
          <div class="GamificationDetails--Info">
            <h1>{{$t('community_config_gamification_question_title')}}</h1>
            <p>{{$t('community_config_gamification_question_explanation')}}</p>
          </div>
          <Separator/>
          <div class="GamificationDetails--ElementWithExample">
            <h2>1.1 {{$t('welcome_labs_text_plain')}}</h2>
            <span v-on:click="$emit('open-image','LABS')">{{ $t('community_config_see_example') }}</span>
          </div>
          <CommunityInput type="textarea" :required="true" v-model="data.pointsExplanation" :placeholder="$t('welcome_labs_explanation')"/>          
          <Separator/>
          <div class="GamificationDetails--Info">
            <h1>{{$t('community_config_gamification_points_title')}}</h1>
            <p>{{$t('community_config_gamification_points_explanation')}}</p>
          </div>
          <Separator/>
          <div class="GamificationDetails--Element">
            <h2>{{$t('community_config_gamification_points_options')}}</h2>
            <div class="GamificationDetails--Switcher">
              <label>{{$t('community_config_gamification_points_hidden')}}</label>
              <SwitchInput class="SwitchInput--Small" v-model="data.privateLabs"/>
            </div>  
          </div>
          <Separator borderType="dashed" borderWidth="2px"/>
          <div class="GamificationDetails--Element">
            <h2>{{$t('community_config_gamification_points_info')}}</h2>
            <div class="GamificationDetails--InputGroup">
              <Input type="number" :placeholder="$t('community_config_gamification_input_points_num')" :label="$t('community_config_gamification_input_month')" v-model="data.pointsCommunityActive"/>
              <Input type="number" :placeholder="$t('community_config_gamification_input_points_num')" :label="$t('community_config_gamification_input_activity')" v-model="data.pointsActivityParticipated"/>
              <Input type="number" :placeholder="$t('community_config_gamification_input_points_num')" :label="$t('community_config_gamification_input_comment')" v-model="data.pointsCommentAppreciated"/>
            </div>
          </div>
        </div>
        <div class="GamificationDetails--Active" v-show="data.showGamification">
          <div class="GamificationDetails--Info">
            <h1>{{$t('community_config_gamification_redeem_title')}}</h1>
            <p>{{$t('community_config_gamification_redeem_explanation')}}</p>
          </div>
          <Separator/>
          <div class="GamificationDetails--Element">
            <h2>{{$t('community_config_gamification_redeem_dates')}}</h2>
            <div class="GamificationDetails--Dates">
              <div class="GamificationDetails--Date">
                <label>{{$t('community_config_gamification_redeem_datefrom')}}</label>
                <p>{{$filters.formatDate(data.pointsRedeemDate,'DD [de] MMMM [de] YYYY')}}</p>
                <input type="date" v-model="data.pointsRedeemDate">
              </div>
              <div class="GamificationDetails--Date">
                <label>{{$t('community_config_gamification_redeem_dateto')}}</label>
                <p>{{$filters.formatDate(data.pointsRedeemDateTo,'DD [de] MMMM [de] YYYY')}}</p>
                <input type="date" v-model="data.pointsRedeemDateTo">
              </div>
            </div>
          </div>
          <Separator borderType="dashed" borderWidth="2px"/>
          <div class="GamificationDetails--Element">
            <h2>{{$t('community_config_gamification_redeem_info')}}</h2>
            <div class="GamificationDetails--RedeemCards">
              <div class="GamificationDetails--RedeemCard" :class="{'disabled':!c.active}" v-for="c in data.redeemCards">
                <div class="GamificationDetails--CardLeft">
                  <ImageSelector v-model="c.logo" v-show="c.logo.length == 0" :publicLogo=false />
                  <div class="GamificationDetails--Img" v-show="c.logo.length > 0">
                    <img :src="c.logo">
                  </div>
                  <div>
                    <span v-show="c.active">{{$t('community_config_gamification_redeem_disable')}}</span>
                    <span v-show="!c.active">{{$t('community_config_gamification_redeem_active')}}</span>
                    <SwitchInput class="SwitchInput--Small" v-model="c.active"/>
                  </div>
                </div>
                <div class="GamificationDetails--CardInputGroup">
                  <CardInput :label="$t('community_config_card_name')" :placeholder="$t('community_config_card_name_placeholder')" v-model="c.name"/>
                  <CardInput :label="$t('community_config_card_value')" :text="$t('community_config_card_value_label')" :placeholder="$t('community_config_card_value_placeholder')" v-model="c.prize"/>
                  <CardInput :label="$t('community_config_card_equivalence')" :text="$t('community_config_card_equivalence_label')" :placeholder="$t('community_config_card_equivalence_placeholder')" v-model="c.labs"/>
                  <CardInput :label="$t('community_config_card_description')" :text="$t('community_config_card_description_label')" :placeholder="$t('community_config_card_description')" v-model="c.description" :isTextarea="true"/>
                </div>
              </div>
              <div class="GamificationDetails--AddCard" v-on:click="addCard()">
                {{$t('community_config_card_add')}}
              </div>
            </div>
          </div>
        </div>
    </div>
 </template>
  
  <script>
    import Swal from 'sweetalert2'
    import Separator from '@/components/Separator'
    import SwitchInput from "~/components/SwitchInput/SwitchInput"
    import CommunityButton from '@/components/CommunityButton.vue';
    import CommunityInput from '@/components/CommunityInput';
    import Input from './Input'
    import CardInput from './CardInput'
    import ImageSelector from './ImageSelector'
    export default {
      name: 'GamificationDetails',
      components: {
        Separator,
        SwitchInput,
        CommunityButton,
        CommunityInput,
        Input,
        CardInput,
        ImageSelector
      },
      props:{
        gamificationData:{
          type: Object,
          default: () => {}
        }
      },
      data(){
        return{
          data:{}
        }
      },
      methods:{
        addCard(){
          if (this.data.redeemCards == undefined){
            this.data.redeemCards = []
          }
          this.data.redeemCards.push({
            logo:'',
            name:'',
            labs:0,
            prize:0,
            description:'',
            active: true
          })
        },
        openModalGamification(){
          Swal.fire({
            title: 'Activar gamificación',
            html:`Si se activa esta opción se habilita el sistema de incentivos de la comunidad. Los usuarios acumularán puntos o LABs en base a su actividad y podrán canjearlos por los diferentes premios que se establezcan.`,
            icon: 'info',
            showCloseButton: false,
            showCancelButton: false,
            confirmButtonText:
              '<i class="fa fa-thumbs-up"></i>',
          })
        }
      },
      mounted(){
        this.data = this.gamificationData
      }
    }
  </script>

  <style scoped lang="scss">
    .GamificationDetails{
      h1{
        font-weight: bold;
        font-size: 22px;
        letter-spacing: 0.01em;
        color: #3E3E3E;
        margin-bottom: 5px;
      }
      &--ActiveGamification{
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }
      &--Switcher{
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 24px;
        label{
          font-family: 'Lato';
          font-weight: 700;
          font-size: 17px;
        }
        i{
          color: #004085;
          background-color: #cce5ff;
          padding: 3px 6px;
          border-radius: 50%;
          font-size: 11px;
          cursor: pointer;
          border: 1px solid #b8daff;
        }
      }
      &--Disabled{
        border: 1px solid #EAEAEA;
        padding: 48px;
        gap: 48px;
        display: flex;
        align-items: center;
        img{
          width: 100%;
        }
        h2{
          font-family: 'Bariol Bold';
          font-weight: 700;
          font-size: 33px;
        }
        p{
          font-family: 'Lato';
          font-weight: 400;
          font-size: 16px;
          color: #626262;
        }
        button{
          font-size: 16px;
          font-family: 'Lato';
          text-transform: capitalize;
        }
      }
      &--Active{
        border: 1px solid #EAEAEA;
        padding: 23px;
        display: flex;
        flex-direction: column;
        gap: 26px;
      }
      &--Info{
        display: flex;
        flex-direction: column;
        gap: 12px;
        h1{
          font-family: 'Bariol Bold';
          font-weight: 700;
          font-size: 26px;
          margin: 0;
        }
        p{
          font-family: 'Lato';
          font-weight: 400;
          font-size: 14px;
          color: #626262;
        }
      }
      &--Element{
        display: flex;
        flex-direction: column;
        gap: 16px;
        h2{
          font-family: 'Bariol Bold';
          font-weight: 700;
          font-size: 20px;
        }
        .GamificationDetails--Switcher{
          margin-bottom: 0px;
        }
        .GamificationDetails--Switcher label{
          font-size: 16px;
          font-family: 'Lato';
          font-weight: 400;
        }
      }
      &--ElementWithExample{
        display: flex;
        flex-direction: inline; 
        gap: 16px;
        h2{
          font-family: 'Bariol Bold';
          font-weight: 700;
          font-size: 20px;
        }
        span{
          color: var(--primary-color-text);
          cursor: pointer;
        }
      }
      &--InputGroup{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px;
      }
      &--Dates{
        display: flex;
        border: 1px solid #EAEAEA;
        border-radius: 8px;
        width: fit-content;
      }
      &--Date{
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 18px;
        &:nth-child(1){
          border-right: 1px solid #EAEAEA;
        }
        label{
          font-family: 'Bariol Bold';
          font-size: 12px;
          background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-transform: uppercase;
        }
        p{
          font-family: 'Bariol Bold';
          font-weight: 700;
          font-size: 18px;
        }
        input{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
        input::-webkit-calendar-picker-indicator {
            background: transparent;
            bottom: 0;
            color: transparent;
            cursor: pointer;
            height: auto;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
        }
      }
      &--AddCard{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14px 24px;
        gap: 32px;
        border: 2px dashed #C8C8C8;
        border-radius: 4px;
        font-size: 16px;
        height: 76px;
        font-family: 'Lato';
        font-weight: 900;
        background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: 0.5s;
        &:hover{
          border: 2px dashed #FF1564;
          background-color: #FFF5F5;
          cursor: pointer;
        }
      }
      &--RedeemCards{
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      &--Img{
        width: 100px;
        height: 75px;
      }
      &--RedeemCard{
        &.disabled{
          img,.GamificationDetails--CardInputGroup{
            opacity: 0.5;
          }
        }
        img{
          width: 100px;
          height: auto;
        }
        background: #F8F8F8;
        border-radius: 4px;
        display: flex;
        gap: 25px;
        padding: 14px;
        @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;

  }
      }
      &--CardLeft{
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
      }
      &--CardInputGroup{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }
    }
  </style>  