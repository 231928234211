<template>
  <div class="CommunityInput" :class="{'CommunityInput--WithError':error,'CommunityInput--Success':success && !disableSuccessClass}">
    <input
      class="CommunityInput--Input"
      :type="type"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : ''"
      @keyup="validate($event,true)"
      v-model="value"
      v-if="type == 'text' || type == 'email' || type == 'number'"
    />
    <input
      class="CommunityInput--Input"
      :type="type"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : ''"
      @keyup="validate($event,true)"
      v-model="value"
      v-if="type == 'password'"
    />
    <input
      class="CommunityInput--Input"
      :type="'text'"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : ''"
      @keyup="validateYoutubeLink($event,true)"
      v-model="value"
      v-if="type == 'youtube'"
    />
    <vue-editor
      class="CommunityInput--HtmlEditor"
      v-if="type == 'html'"
      v-model="value"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : ''"
      :editorToolbar="customToolbar"
      @text-change="validate($event,true)">
    </vue-editor>
    <Datepicker
      ref="datePicker"
      @update:modelValue="validateDate($event, true)"
      :placeholder="placeholder"
      v-model="value"
      :format="format"
      :textInputOptions="textInputOptions"
      :disabled="disabled"
      :locale="lang"
      teleport-center
      :auto-position="true"
      :selectText = "$t('action_accept')"
      :cancelText = "$t('action_cancel')"
      time-picker-inline
      text-input
      :year-range="yearRange"
      :is-24="true"
      v-if="type == 'dateTesterProfile'"></Datepicker>
    <Datepicker
      ref="datePicker"
      @update:modelValue="validateDate($event, true)"
      :placeholder="placeholder"
      v-model="value"
      :format="format"
      :textInputOptions="textInputOptions"
      :disabled="disabled"
      :locale="lang"
      position="bottom-start"
      teleport="body"
      :auto-position="true"
      :selectText = "$t('action_accept')"
      :cancelText = "$t('action_cancel')"
      time-picker-inline
      text-input
      :year-range="yearRange"
      :is-24="true"
      v-if="type == 'date' || type == 'datetime'"></Datepicker>

    <textarea class="CommunityInput--Textarea" v-model="value" @keyup="validate($event,true)" :placeholder="placeholder ? placeholder : ''" v-if="type == 'textarea'"></textarea>
    <div class="CommunityInput--Error" v-show="error && !hideErrorMessage">
      {{error}}
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue3-editor";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {verifyEmail} from '@/utils'
import { isEmptyText, isYouTubeVideo } from '@/utils';

export default {
  name: 'CommunityInput',
  components:{
    VueEditor,
    Datepicker
  },
  data() {
    return {
      validationsNum:0,
      error       : false,
      success: false,
      customToolbar: [
        [{ 'font': [] }],
        [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        [{ 'header': 1 }, { 'header': 2 }],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'color': [] }, { 'background': [] }],
        ['link', 'formula'],
        [{ 'direction': 'rtl' }],
        ['clean'],
      ],
      textInputOptions: {
        enterSubmit: true
      }
    }
  },
  watch: {
   value (newValue) {
     if(typeof newValue === "object" && newValue != null) this.value = newValue.toString()
     this.$emit('update:modelValue', newValue)
   },
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
  emits: ['update:modelValue','change','key-up'],
  props: {
    modelValue         : [Number, String, Date],
    type               : String,
    disabled           : Boolean,
    required           : Boolean,
    email              : Boolean,
    minLength          : Number,
    maxLength          : Number,
    max                : {type:Number, default: 10000000000},
    placeholder        : String,
    lang               : String,
    format             : String,
    timePickerOptions  : Object,
    disableSuccessClass: Boolean,
    stopFirstValidation: Boolean,
    hideErrorMessage: Boolean,
    yearRange   : {
      type: Array,
      default: [1900, 2100]
    },
    passErrorMessage:{
      type:String,
      default:''
    }
  },
  methods: {
    validateDate(event,emit){
      this.error = false
      this.success = false
      let hasError = false
      if(this.required && ((this.value == undefined || this.value == null) && (event == undefined || event == null)) ){
        this.error = this.$t('validation_required')
        hasError = true
      }
      if(!hasError) this.success = true
      if(emit) this.$emit('change',event)
      return hasError
    },
    validate(event,emit){
      if(this.stopFirstValidation && this.validationsNum == 0){
        this.validationsNum++
        return
      }
      this.error = false
      this.success = false
      let hasError = false
      if(this.type == 'password' && this.passErrorMessage.length > 0 && !this.validatePassword()){
        this.error = this.passErrorMessage
        hasError = true
      }
      else if(this.required && ( this.value == undefined || this.value.length == 0)){
        this.error = this.$t('validation_required')
        hasError = true
      }
      else if(this.required && this.minLength > this.value.length){
        this.error = this.$t('validation_min_length') + this.minLength
        hasError = true
      }
      else if(this.required && this.type == 'email' && !verifyEmail(this.value)){
        this.error = this.$t('validation_email')
        hasError = true
      }
      else if(this.type == "number" && parseInt(this.value) > this.max){
        this.error = this.$t('validation_max_number', { max:this.max })
        hasError = true
      }
      if(!hasError) this.success = true
      if(emit) this.$emit('key-up',{event:event,error:hasError})
      return hasError
    },
    validatePassword(){
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
      const valid = passwordRegex.test(this.value);
      return valid
    },
    validateYoutubeLink(event,emit){
      this.error = false
      this.success = false
      let hasError = false
      if(this.required && isEmptyText(this.value)){
        this.error = this.$t('validation_required')
        hasError = true
      }
      if(!isEmptyText(this.value) && !isYouTubeVideo(this.value)){
        this.error = this.$t('validation_youtubelink')
        hasError = true
      }

      if(!hasError) this.success = true
      if(emit)this.$emit('key-up',{event:event,error:hasError})
      return hasError
    },
  },
}
</script>

<style lang="scss">
.CommunityInput{
  .mx-input-append{
    background: transparent;
    margin: 5px;
    svg{
      color: var(--accent-color);
    }
  }
  &--HtmlEditor{
    .ql-editor{
      background: #F5F5F5;
      border: 1px solid #D3D3D3;
      margin-top:13px;
      color: #969696;
      font-size: 22px;
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
    }
    .ql-blank{
      &:before{
        font-style:normal!important;
      }
    }
  }
  &--WithError{
    input,.ql-editor, .CommunityInput--Textarea{
      background: #FFF0F0!important;
      border: 2px solid #F98787!important;
    }
  }
  &--Success{
    input,.ql-editor{
      border: 2px solid #ACE949!important;
    }
  }
}

</style>
<style lang="scss" scoped>
.CommunityInput{
  position: relative;
  &--Input{
    background      : #f4f4f4;
    border          : 1px solid #e4e4e4;
    font-size       : 1.3rem;
    padding         : .5rem 1rem;
    width           : 100%;
    font-family: Roboto condensed;
    letter-spacing: 0.01em;
    outline: none;
    &::placeholder{
      opacity: 0.6;
      @media only screen and (max-width: 600px) {
      font-size: 20px;
      }
    }
  }
  &--Textarea{
    width: 100%;
    height: 150px;
    resize:none;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    letter-spacing: 0.01em;
    color: #969696;
    //background: #F5F5F5;
    border: 1px solid #D3D3D3;
    padding: 10px;
    outline: none;
  }
  &--Error{
    font-weight: 300;
    font-size: 15px;
    letter-spacing: 0.01em;
    color: #F40707;
    position: absolute;
    z-index:2;
  }
}
</style>
